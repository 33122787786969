import * as PurgeTypes from "../src/purge/types";
export { PurgeTypes };
export var HttpMethod;
(function(HttpMethod) {
    HttpMethod["GET"] = "GET";
    HttpMethod["POST"] = "POST";
    HttpMethod["PUT"] = "PUT";
    HttpMethod["PATCH"] = "PATCH";
    HttpMethod["DELETE"] = "DELETE";
})(HttpMethod || (HttpMethod = {}));
export var PageType;
(function(PageType) {
    PageType["ConferenceMarketing"] = "ConferenceMarketing";
    PageType["ConferencePortal"] = "ConferencePortal";
})(PageType || (PageType = {}));
export var Layouts;
(function(Layouts) {
    Layouts["Home"] = "Home";
    Layouts["Marketing"] = "Marketing";
    Layouts["Content"] = "Content";
    Layouts["Search"] = "Search";
})(Layouts || (Layouts = {}));
export var TwitterCard;
(function(TwitterCard) {
    TwitterCard["summary"] = "summary";
    TwitterCard["summary_large_image"] = "summary_large_image";
})(TwitterCard || (TwitterCard = {}));
export var Product;
(function(Product) {
    Product["BCIQ"] = "BCIQ";
    Product["BioCentury"] = "BioCentury";
    Product["Infinity"] = "Identity";
    Product["Conferences"] = "Conferences";
    Product["Profiles"] = "Profiles";
})(Product || (Product = {}));
export var GraphCMSModel;
(function(GraphCMSModel) {
    GraphCMSModel["Article"] = "Article";
    GraphCMSModel["ArticlePackage"] = "ArticlePackage";
    GraphCMSModel["Conference"] = "Conference";
    GraphCMSModel["Page"] = "Page";
})(GraphCMSModel || (GraphCMSModel = {}));
export var ContextType;
(function(ContextType) {
    ContextType["Nextjs"] = "Nextjs";
    ContextType["Azure"] = "Azure";
})(ContextType || (ContextType = {}));
export var FetchAPI;
(function(FetchAPI) {
    FetchAPI["DEFAULT"] = "default";
    FetchAPI["IDENTITY"] = "identity";
    FetchAPI["ARTICLE"] = "article";
    FetchAPI["ALERT"] = "alert";
})(FetchAPI || (FetchAPI = {}));
export var RestMethods;
(function(RestMethods) {
    RestMethods["GET"] = "GET";
    RestMethods["POST"] = "POST";
    RestMethods["PUT"] = "PUT";
    RestMethods["DELETE"] = "DELETE";
})(RestMethods || (RestMethods = {}));
export var ArticleType;
(function(ArticleType) {
    ArticleType["Article"] = "Article";
    ArticleType["Podcast"] = "Podcast";
    ArticleType["Presentation"] = "Presentation";
    ArticleType["Whitepaper"] = "Whitepaper";
    ArticleType["Webinar"] = "Webinar";
    ArticleType["Collection"] = "Collection";
    ArticleType["Event"] = "Event";
    ArticleType["BioCenturyGraphic"] = "BioCenturyGraphic";
})(ArticleType || (ArticleType = {}));
export var SponsorType;
(function(SponsorType) {
    SponsorType["Organizers"] = "Organizers";
    SponsorType["Founding"] = "Founding";
    SponsorType["Insights"] = "Insights";
    SponsorType["RegionalCommitee"] = "RegionalCommitee";
    SponsorType["Gold"] = "Gold";
    SponsorType["Silver"] = "Silver";
    SponsorType["Supporting"] = "Supporting";
})(SponsorType || (SponsorType = {}));
export var ModelType;
(function(ModelType) {
    ModelType["Article"] = "Article";
    ModelType["Company"] = "Company";
    ModelType["Target"] = "Target";
    ModelType["Product"] = "Product";
    ModelType["Disease_Category"] = "Disease Category";
    ModelType["Page"] = "Page";
    ModelType["Search"] = "Search";
    ModelType["Events"] = "Events";
    ModelType["Whitepaper"] = "Whitepaper";
})(ModelType || (ModelType = {}));
export var OrderOption;
(function(OrderOption) {
    OrderOption["ASC"] = "ASC";
    OrderOption["DESC"] = "DESC";
})(OrderOption || (OrderOption = {}));
export var QueryModel;
(function(QueryModel) {
    QueryModel["articlesConnection"] = "articlesConnection";
    QueryModel["algolia"] = "algolia";
})(QueryModel || (QueryModel = {}));
export var RoleType;
(function(RoleType) {
    RoleType["Admin"] = "Admin";
})(RoleType || (RoleType = {}));
export var SynonymType;
(function(SynonymType) {
    SynonymType["Synonym"] = "synonym";
    SynonymType["OneWaySynonym"] = "oneWaySynonym";
    SynonymType["AltCorrection1"] = "altCorrection1";
    SynonymType["AltCorrection2"] = "altCorrection2";
    SynonymType["Placeholder"] = "placeholder";
})(SynonymType || (SynonymType = {}));
export var BlockTypeNamePlural;
(function(BlockTypeNamePlural) {
    BlockTypeNamePlural["buttonComponent"] = "buttonComponents";
    BlockTypeNamePlural["carouselComponent"] = "carouselComponents";
    BlockTypeNamePlural["checkoutButtonComponent"] = "checkoutButtonComponents";
    BlockTypeNamePlural["collapsibleComponent"] = "collapsibleComponents";
    BlockTypeNamePlural["companiesComponent"] = "companiesComponents";
    BlockTypeNamePlural["conferenceSection"] = "ConferenceSections";
    BlockTypeNamePlural["contentCardComponent"] = "contentCardComponents";
    BlockTypeNamePlural["informativeCardComponent"] = "informativeCardComponents";
    BlockTypeNamePlural["gridComponent"] = "gridComponents";
    BlockTypeNamePlural["loginButtonComponent"] = "loginButtonComponents";
    BlockTypeNamePlural["markdownComponent"] = "markdownComponents";
    BlockTypeNamePlural["marketingCardComponent"] = "marketingCardComponents";
    BlockTypeNamePlural["masoneryComponent"] = "masoneryComponents";
    BlockTypeNamePlural["myFeedComponent"] = "myFeedComponents";
    BlockTypeNamePlural["myFoldersComponent"] = "myFoldersComponents";
    BlockTypeNamePlural["peopleComponent"] = "peopleComponents";
    BlockTypeNamePlural["podcastButtonComponent"] = "podcastButtonComponents";
    BlockTypeNamePlural["registerButtonComponent"] = "registerButtonComponents";
    BlockTypeNamePlural["tabComponent"] = "tabComponents";
    BlockTypeNamePlural["tabItemComponent"] = "tabItemComponents";
    BlockTypeNamePlural["titleComponent"] = "titleComponents";
})(BlockTypeNamePlural || (BlockTypeNamePlural = {}));
